import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static get targets() {
		return ['close'];
	}

	connect() {
		this.closeTarget.addEventListener('click', () => {
			this.element.classList.add('d-none');
		});
	}
}