import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        const showEl = document.querySelector(this.element.dataset.show);
        const hideEl = document.querySelector(this.element.dataset.hide);

        this.element.addEventListener('change', () => {
            if (this.element.checked) {
                showEl.classList.remove('d-none');
                hideEl.classList.add('d-none');
            } else {
                showEl.classList.add('d-none');
                hideEl.classList.remove('d-none');
            }
        });
    }
}
